<script lang="ts">
    import { getContext, onMount } from 'svelte'

    import type { TabControl } from '../types'
    import TabsLink from './TabsLink.svelte'

    let classNames = ''
    export { classNames as class }
    export let active = false
    export let id: string

    const tabs = getContext('tabs')
    const { activeTabId, setActiveTab }: TabControl = getContext('tabControl')

    onMount(() => {
        if (active) setActiveTab(id)
    })

    $: open = $activeTabId === id
    $: classes = ['tab-item', open ? 'active' : '', classNames].join(' ')
</script>

{#if tabs}
    <TabsLink {id} {open} {setActiveTab}>
        <slot name="tab" />
    </TabsLink>
{:else}
    <div
        id={`panel-${id}`}
        class={classes}
        {...$$restProps}
        aria-labelledby={`tab-${id}`}
        data-cy="componentTabsTabItem"
        role="tabpanel"
        tabindex="0"
    >
        <slot />
    </div>
{/if}

<style global lang="postcss">
    .tab-item {
        display: none;
        padding-top: theme('spacing.4');
    }

    .tab-item.active {
        display: block;
    }
</style>
