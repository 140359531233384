<script lang="ts">
    import { setContext } from 'svelte'

    let classNames = ''
    export { classNames as class }

    setContext('tabs', true)
</script>

<div
    class="tabs-header {classNames}"
    data-cy="componentTabsTablist"
    role="tablist"
>
    <slot />
</div>

<style global lang="postcss">
    .tabs-header {
        display: flex;
        flex-wrap: nowrap;
        gap: theme('spacing.1');
        width: 100%;
        overflow-x: auto;
        border-bottom: theme('borderWidth.DEFAULT') solid
            theme('colors.gray-400');
    }
</style>
