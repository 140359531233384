<script lang="ts">
    import Button from './Button.svelte'

    let classNames = ''
    export { classNames as class }
    export let active = false
    export let href = ''
</script>

{#if href}
    <a class="app-tile {classNames}" class:active {href} {...$$restProps}>
        <slot name="image" />

        <div class="app-tile-title">
            <slot name="title" />
        </div>
    </a>
{:else}
    <div
        class="app-tile app-tile--gray {classNames}"
        class:active
        {...$$restProps}
    >
        <slot name="image" />

        <Button class="app-tile-btn" variant="success" on:click>
            <slot name="button">Run</slot>
            <span class="app-tile-title">
                <slot name="title" />
            </span>
        </Button>
    </div>
{/if}

<style global lang="postcss">
    .app-tile {
        position: relative;
        text-align: center;
        border: theme('borderWidth.DEFAULT') solid theme('colors.gray-200');
        border-radius: theme('borderRadius.DEFAULT');
        cursor: pointer;

        &:hover,
        &.active {
            color: theme('colors.green.600');
            border-color: theme('colors.green.500');
            background-color: theme('colors.white');
        }
    }

    .app-tile img {
        margin: auto;
        margin-top: -0.75rem;
        opacity: 1;
        transition: theme('transitionDuration.DEFAULT') ease-in-out opacity;
    }

    .app-tile-btn {
        padding: theme('spacing.2') theme('spacing.5');
        margin-top: -0.75rem;
        margin-bottom: -1.25rem;
        font-size: 1.25rem;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: theme('zIndex.20');
            display: block;
            width: 100%;
            height: 100%;
            background-color: transparent;
        }
    }

    .app-tile-title {
        position: absolute;
        top: 75%;
        left: 50%;
        font-size: theme('fontSize.sm');
        white-space: nowrap;
        opacity: 1;
        transform: translateX(-50%);
        transition: theme('transitionDuration.DEFAULT') ease-in-out opacity;
    }

    .app-tile:hover .app-tile-title,
    .app-tile.active .app-tile-title {
        color: theme('colors.green-600');
    }

    /* stylelint-disable-next-line selector-class-pattern */
    .app-tile.app-tile--gray {
        border-color: theme('colors.gray-100');
        background-color: theme('colors.gray-100');

        .app-tile-title {
            opacity: 0;
            transform: translate(-50%, -50%);
        }

        &:hover,
        &.active {
            border-color: theme('colors.green-500');
        }
    }
</style>
