<script lang="ts">
    // eslint-disable-next-line
    import { setContext } from 'svelte'
    // eslint-disable-next-line
    import { writable } from 'svelte/store'

    import type { TabControl } from '../types'
    import TabsHeader from './TabsHeader.svelte'

    let classNames = ''
    export let classHeader = ''
    export { classNames as class }

    const activeTabId = writable('')
    setContext<TabControl>('tabControl', {
        activeTabId,
        setActiveTab: (id: string) => {
            activeTabId.set(id)
        },
    })
</script>

<div {...$$restProps} class="tabs {classNames}" data-cy="componentTabs">
    <TabsHeader class={classHeader}>
        <slot />
    </TabsHeader>

    <slot />
</div>

<style global lang="postcss">
    .tabs {
        position: relative;
    }
</style>
